<!-- 
	author:张洪志
	description:当前位置 
-->
<template>
	<section class="site-nav">
		<div class="vw">
			<span>当前位置:</span>
      <router-link to="/">众惠首页</router-link>
      <slot></slot>
		</div>
	</section>
</template>

<script>
export default {
	name: 'SiteNav'
}
</script>

<style lang="less" scoped>	
	.site-nav{ line-height: 64px;
    .vw{ background: none;}
    a,span{ color:#999; margin-right: 8px;}
    span:last-child{color: #666;}
	}
</style>
